<template>
  <div>
    <video
      ref="videoPlayer"
      class="video-js"
      :data-title="options.data.title"
      :data-description="options.data.description"
    />
  </div>
</template>

<script setup lang="ts">
  import videojs from "video.js"
  import type Player from "video.js/dist/types/player"
  import "video.js/dist/video-js.css"

  const props = defineProps<{
    options: Player
  }>()

  const videoPlayer = ref<HTMLVideoElement | null>( null )
  const player = ref<Player | null>()

  onMounted( () => {
    player.value = videojs( videoPlayer.value as HTMLVideoElement, props.options, () => {
      // player.value?.log( "onPlayerReady", this )
    } )
  } )
  onBeforeUnmount( () => {
    if ( player.value ) {
      player.value.dispose()
    }
  } )
  </script>
