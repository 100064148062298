<template>
  <div>
    <div
      ref="letterbox"
      class="w-full border-cbc relative overflow-hidden aspect-video sm:h-[640px] md:h-[420px] lg:h-[480px] xl:h-[530px] 2xl:h-[600px]"
    >
      <div
        ref="parallaxVideo"
        class="absolute left-1/2 w-full h-full -translate-x-1/2 top-0 md:top-[-10px] lg:top-[-49px] xl:top-[-96px] 2xl:top-[-214px]"
      >
        <videojs-player
          :options="{
            autoplay: true,
            loop: true,
            muted: true,
            playsinline: true,
            preload: 'auto',
            fluid: true,
            poster: '/images/clifton-bootcamp-cover.jpg',
            sources: [
              {
                src: '/videos/clifton-bootcamp-fs.mp4',
                type: 'video/mp4',
              }
            ],
            data: {
              title: 'Clifton Bootcamp',
              description: 'Clips of Clifton Bootcamp classes taking place on Clifton Downs',
            },
          }"
        />
      </div>
    </div>
    <page-section v-fadeIn="{duration: 0.6, delay: 0.3 }">
      <section-header>Transform your fitness in the great outdoors</section-header>
      <text-narrow>
        Experience the ultimate workout in a supportive environment where discipline meets determination. Join us in the fresh air, conquer your goals, and discover a stronger, fitter you.
      </text-narrow>
      <text-narrow>
        Ready to take the challenge?<br>
        Sign up today and start your journey with Clifton Bootcamp.
      </text-narrow>
      <div class="flex justify-center items-center space-y-6 md:space-y-0 md:space-x-8 pt-8 flex-col md:flex-row">
        <button
          type="button"
          class="rounded-sm bg-cbc-green py-3 w-72 text-lg font-semibold text-black uppercase hover:cbc-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cbc-green-600"
          @click="$openTrialModal()"
        >
          Book A Free Trial
        </button>
        <nuxt-link
          role="button"
          class="rounded-sm bg-cbc-green py-3 w-72 text-lg font-semibold text-black uppercase hover:cbc-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cbc-green-600"
          to="/classes"
        >
          Discover Our Classes
        </nuxt-link>
      </div>
    </page-section>
    <ParallaxBanner
      class="h-[200px] sm:h-[180px] md:h-[180px] lg:h-[200px] xl:h-[300px] 2xl:h-[400px]"
      :speed="0.2"
    >
      <div class="flex justify-center h-full w-full">
        <NuxtImg
          src="/images/tyre-push.jpg"
          alt="Photo of tyre push exercise"
          sizes="425 sm:320 md:256 lg:340 xl:600"
          class="w-1/2 md:w-1/3 hidden sm:block"
          preload
        />
        <NuxtImg
          src="/images/downs-sunset.jpg"
          alt="Photo of session during sunset on Clifton Downs"
          sizes="425 sm:320 md:256 lg:340 xl:600"
          class="w-full sm:w-1/2 md:w-1/3"
          preload
        />
        <NuxtImg
          src="/images/post-session.jpg"
          alt="Photo of thrusters with bands"
          sizes="425 sm:320 md:256 lg:340 xl:600"
          class="w-1/3 hidden md:block"
          preload
        />
      </div>
    </ParallaxBanner>
    <page-section>
      <section-header>Why choose Clifton Bootcamp?</section-header>
      <div class="flex flex-col md:flex-row justify-center pt-8">
        <div
          v-slideIn="{ duration: 0.6, delay: 0.3 }"
          class="md:w-1/4"
        >
          <div class="h-32 lg:h-48">
            <img
              src="/icons/SVG/Classes.svg"
              alt="Whistle icon"
              class="h-full m-auto"
            >
          </div>
          <h4 class="font-semibold text-lg pt-8">
            Authentic bootcamp training
          </h4>
          <p class="text-center p-4">
            Led by experienced instructors, Clifton Bootcamp offers challenging workouts designed to push your limits and deliver real fitness results.
          </p>
        </div>
        <div
          v-slideIn="{ duration: 0.6, delay: 0.4 }"
          class="md:w-1/4"
        >
          <div class="h-32 lg:h-48">
            <img
              src="/icons/SVG/NaturesPlayground.svg"
              alt="Outdoors icon"
              class="h-full m-auto"
            >
          </div>
          <h4 class="font-semibold text-lg pt-8">
            Nature&apos;s playground
          </h4>
          <p class="text-center p-4">
            Train on the scenic Clifton Downs enjoying the fresh air while enhancing your physical and mental wellbeing &ndash; come rain or shine.
          </p>
        </div>
        <div
          v-slideIn="{ duration: 0.6, delay: 0.5 }"
          class="md:w-1/4"
        >
          <div class="h-32 lg:h-48">
            <img
              src="/icons/SVG/Community.svg"
              alt="Community icon"
              class="h-full m-auto"
            >
          </div>
          <h4 class="font-semibold text-lg pt-8">
            Community spirit
          </h4>
          <p class="text-center p-4">
            Join a welcoming community where everyone motivates you to succeed. Outside of classes, there are many social events to take part in.
          </p>
        </div>
        <div
          v-slideIn="{ duration: 0.6, delay: 0.6 }"
          class="md:w-1/4"
        >
          <div class="h-32 lg:h-48">
            <img
              src="/icons/SVG/AllLevels.svg"
              alt="Levels icon"
              class="h-full m-auto"
            >
          </div>
          <h4 class="font-semibold text-lg pt-8">
            All levels welcome
          </h4>
          <p class="text-center p-4">
            Our bootcamp is designed for everyone, with flexible workouts tailored to your individual fitness level and goals.
          </p>
        </div>
      </div>
    </page-section>
    <find-us />
    <join-us />
  </div>
</template>

<script setup lang="ts">
  const { $openTrialModal } = useNuxtApp()

  useHead( {
    title: "Home",
    meta: [
      { name: "description", content: "Clifton Bootcamp Homepage" }
    ],
  } )

  defineOgImageComponent( "Nuxt", {
    icon: "/images/cbc-logo.png",
    siteLogo: "/images/cbc-logo-full.png"
  } )

  // Define refs for DOM elements
  const letterbox = ref<HTMLDivElement | null>( null )
  const parallaxVideo = ref<HTMLDivElement | null>( null )

  const handleScroll = () => {
    // Ensure that the DOM elements are available
    if ( letterbox.value && parallaxVideo.value ) {
      const scrollPosition = window.scrollY
      const letterboxTop = letterbox.value.offsetTop
      const letterboxHeight = letterbox.value.offsetHeight

      // Only apply the parallax effect when the scroll is within the letterbox section
      if ( scrollPosition > letterboxTop - window.innerHeight && scrollPosition < letterboxTop + letterboxHeight ) {
        const offset = Math.max( ( scrollPosition - letterboxTop ) * 1.0, 0 ) // Adjust speed of video (0.5 = slower)
        parallaxVideo.value.style.transform = `translate(-50%, ${offset}px)`
      }
    }
  }

  // Register the scroll event when the component is mounted
  onMounted( () => {
    window.addEventListener( "scroll", handleScroll )
  } )

  // Clean up the event listener when the component is destroyed
  onBeforeUnmount( () => {
    window.removeEventListener( "scroll", handleScroll )
  } )
</script>
